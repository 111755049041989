define([], function() {
  /*
  * Columbo Constants
  * ====================
  *
  *  All of the constants used throughout the
  *  Columbo tracking service are stored here.
  *
   */
  const Constants = {
    columboVersion: '2.2',
    chaseSessionKey: 'chumewe_sess',
    chaseUserKey: 'chumewe_user',
    gtmEventNameAppend: '_Columbo',
    filterAtts:  [
      'data-context',
      'data-component',
      'data-component-tracked-viewed',
      'data-component-tracked-clicked',
      'class',
      'data-widget-id'
    ]
  };

  return Constants;
});
